import React  from 'react'
import {
    Form,
    Message,
} from 'semantic-ui-react'
import {
    DateInput,
    TimeInput,
} from 'semantic-ui-calendar-react';
import * as Yup from "yup";

import ContraComponent from '../../utils/ContravariantComponent';

export const dates_default = {
    day: '',
    time: '',
};

export const dates_validation = {
    time: Yup.string()
        .required("The time is required."),
    day: Yup.string()
        .required("The day is required."),
};

// just a simple UI getting props from parent
const Dates = ({   dates_title, dates_message, day_of_event_label,time_start_label,
                   values,  errors, touched,
                   dateInputOnchange, dateOnBlur, timeOnChange, timeOnBlur
               }) =>
        <>
            <Message>
                <Message.Header>{dates_title}</Message.Header>
                <p>{dates_message}</p>
            </Message>
            <Form.Group widths="equal">
                    <DateInput
                    name="day"
                    placeholder={day_of_event_label}
                    iconPosition="left"
                    value={values.day}
                    onChange={dateInputOnchange}
                    onBlur={dateOnBlur}
                    error={errors.day && touched.day}
                    />
                <TimeInput
                    name="time"
                    placeholder={time_start_label}
                    value={values.time}
                    iconPosition="left"
                    onChange={timeOnChange}
                    onBlur={timeOnBlur}
                    disableMinute={true}
                    error={errors.time && touched.time}
                />

            </Form.Group>
        </>;

//preprocess anything needed before passing the props down
export default ContraComponent.of(Dates).contramap(props  => ({
    ...props,
    ...props.form_dates,
    dateInputOnchange: (event, {name, value}) => props.setFieldValue(name, value),
    dateOnBlur: () => props.setFieldTouched("day", true),
    timeOnChange: (event, {name, value}) => props.setFieldValue(name, value),
    timeOnBlur: () => props.setFieldTouched("time", true)
}));