import { createStore, applyMiddleware, combineReducers } from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';


import { calendarReducer } from './100Pages';
import errorReducer from './ErrorsReducers';


const firebaseReducer = combineReducers({
    events: calendarReducer,
    errors: errorReducer,
});

let store;

// redux compose cannot handle a null or undefined middleware
// if (window.navigator.userAgent.includes('Chrome')) {
//     store =  createStore(
//         firebaseReducer,
//             compose(
//                 applyMiddleware(thunk)
//             )
//     );
// } else {
store = createStore(
    firebaseReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);



export default store;