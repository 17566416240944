import { docRef } from "../../firebaseService";
import {
    FETCH_EVENTS,
    FETCH_EVENTS_ERROR,
    ADD_EVENTS,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAILED,
} from "./Calendar.constants";


export const fetchEvents = () => {
    return dispatch => {
        docRef.get()
            .then(doc => {
                if (doc && doc.exists){
                    const allData = doc.data();
                    const { events = [] } = allData;
                    // format the date to match the calendar library
                    const formatedEvents = events.map(event => {
                        return {
                            ...event,
                            end: event.end.toDate(),
                            start: event.start.toDate()
                        }
                    });
                    dispatch({
                        type: FETCH_EVENTS,
                        payload: {events: formatedEvents}
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: FETCH_EVENTS_ERROR,
                    payload: error
                })
            })
    }
};


//{ id }
export const deleteEvent = (event) => {
    return (dispatch, getState) => {
        const prevState = getState();
        const { events } = prevState;
        const { id } = event;

        //maybe some sort of checking if event is not duplicate
        const newEvents = events.filter(each => each.id !== id);

        const newState = {
            events: newEvents
        };

        docRef.set(newState)
            .then(() => {
                dispatch({
                    type: DELETE_EVENT_SUCCESS,
                    payload: {events: newEvents}
                })
            })
            .catch( error => {
                dispatch({
                    type: DELETE_EVENT_FAILED,
                    payload: error
                })
            });
    }
}

export const addEvents = events => ({
    type: ADD_EVENTS,
    payload: events
})