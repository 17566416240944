import {
    FETCH_EVENTS_ERROR,
    DELETE_EVENT_FAILED,
} from "./100Pages/Calendar/Calendar.constants";


const initialState = [];

export default (state=initialState, action) => {
    switch(action.type){
        case FETCH_EVENTS_ERROR:
        case DELETE_EVENT_FAILED:
            const { payload } = action;
            const { error } = payload;
            return state.concat(error);
        default:
            return state;
    }
}

