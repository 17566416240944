import React from 'react';
import {
    Header,
    Container,
    Grid,
    Card,
} from 'semantic-ui-react'
import { Link } from "react-router-dom";

import pricingBackground from '../images/price_background.jpg';
import pricingAreal from '../images/pricing_areal_view.jpg';
import {pricing } from '../AppData.json';

const { pricing_title, items=[], disclaimer } = pricing;



const pricingItems = items.map((item, index) =>
    <Grid.Column key={index} >
        <Card style={{height: "16rem"}} as={Link} to={{
            pathname: "/Booking",
            state: item,
        }}>
            <Card.Content
         style={{
                backgroundImage: `url(${pricingBackground})`,
                backgroundSize: "cover",
                clipPath: "polygon(0 0, 100% 0, 100% 77%, 0 96%)"
            }}>
                <Card.Header style={{textAlign: "center"}}>{item.title}</Card.Header>
                <Card.Meta style={{
                    textAlign: "center",
                    color: "green",
                    fontWeight: "700",
                    fontSize: "2em",
                    margin: ".5em 0",
                }}>
                    {item.price}
                </Card.Meta>
                <Card.Description>
                    {item.description}
                </Card.Description>
            </Card.Content>
        </Card>

    </Grid.Column>
);

//

const Pricing = () => (
    <>
        <div style={{
            backgroundImage: `url(${pricingAreal})`,
            backgroundSize: "cover",
            position: "absolute",
            width: "100%",
            height: "215%",
        }}>

        </div>
        <Container>
            <Header as='h2' icon textAlign='center' style={{position: 'relative', margin: "2rem 0"}}>
                <Header.Content>{pricing_title}</Header.Content>
            </Header>
            <Header as='h2' icon style={{position: 'relative', margin: "2rem 0", color: "red"}}>
                <Header.Content>{disclaimer}</Header.Content>
            </Header>
            <Grid columns={3} doubling>
                {pricingItems}
            </Grid>
        </Container>
    </>
);

export default Pricing;