import React from 'react';
import { Menu, Container } from 'semantic-ui-react';
import ActualMenuItems from './ActualMenuItems';

import Appmenu from '../AppData.json';

const { sitename } = Appmenu;

const DesktopMenu = () => (
    <Menu fixed="top" inverted fluid>
        <Container>
            <Menu.Item>
                {sitename}
            </Menu.Item>
            <ActualMenuItems />
        </Container>
    </Menu>
);

export default DesktopMenu;
