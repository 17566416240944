import {
    FETCH_EVENTS,
    ADD_EVENTS,
    DELETE_EVENT_SUCCESS,
} from "./Calendar.constants";

const initialState = [];

export default (state=initialState, action) => {
    const { type, payload } = action;

    switch(type){
        case FETCH_EVENTS:
        case ADD_EVENTS:
        case DELETE_EVENT_SUCCESS:
            const { events=[] } = payload;
            return events;
        default:
            return state;
    }
}