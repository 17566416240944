import React  from 'react'
import {
    Form,
    Message,
    Label,
} from 'semantic-ui-react';

import appData from '../../AppData';
import ContraComponent from '../../utils/ContravariantComponent';

const { pricing={} } = appData;
const { items=[], disclaimer } =  pricing;
const options = items.map(item => ({
    key: `${item.title} : ${item.price}`,
    text: `${item.title} : ${item.price}`,
    value: item.price
}));
//just in case there is nothing in appData
const [
    firstItem={
        selected_value: '',
        item_price: '',
        item_description: '',
        total: ''
}] = items;
export const mapServicesToDefault = props => {
    // needs to check for state from Pricing clicking
    const { location } = props;
    const { state={} } = location;
    const { price=''} = state;
    const founded = items.find(item => item.price === price);
    //default case
    let service_default = {
        selected_value: `${firstItem.title} ${firstItem.price}`,
        item_price: `${firstItem.price}`,
        item_description: `${firstItem.description}`,
        total: `${firstItem.price}`,
    };

    if (founded){
        //override case where user click from price
        service_default = {
            selected_value: `${founded.title} ${founded.price}`,
            item_price: `${founded.price}`,
            item_description: `${founded.description}`,
            total: `${founded.price}`,
        };
    }

    return service_default;
};

const Services = ({services_title, services_message, values, selectOnChange,}) =>
        <>
        <Message>
            <Message.Header>{services_title}</Message.Header>
            <p style={{color: "red"}}>{services_message} {disclaimer}</p>
        </Message>
            <Form.Select
                style={{marginTop: "1em", marginBottom: "1em"}}
                options={options}
                name="selected_value"
                defaultValue={values.selected_value}
                placeholder={values.selected_value}
                onChange={selectOnChange}
            />
            <Form.TextArea value={values.item_description} style={{
                fontSize: "2em",
                lineHeight: "1.2em",
            }}/>

            <Label as='a' style={{
                textAlign: "right",
                color: "green",
                fontSize: "2em",
                cursor: "default",
                float: "right",
            }}>
                Total:
                <Label.Detail>{values.total}</Label.Detail>
            </Label>
        </>;

export default ContraComponent.of(Services).contramap(props => ({
    ...props,
    ...props.form_services,
    selectOnChange: (_, {name, value}) => {
        const founded = items.find(item => item.price === value);
        if (founded){
            props.setValues({
                ...props.values,
                [name]: value,
                total: value,
                item_description: founded.description
            });
        }
    },
}));