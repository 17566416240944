import React from 'react';
import {
    Container,
    Responsive
} from 'semantic-ui-react'

import MobileMenu from './mobileMenu';
import DesktopMenu from './desktopMenu';

const HeaderContainer = () => (
    <Container>
        <Responsive {...Responsive.onlyMobile}>
            <MobileMenu />
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <DesktopMenu/>
        </Responsive>

    </Container>
);

export default HeaderContainer;