import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';


//From Headers
import Header from './000Header/Header';


//From Pages
import {
    Home, PhotoGallery, VideoGallery,Pricing,
    BookingForm,
    BookingConfirmation, SecretAdminEvents,
    fetchEvents,
} from './100Pages'


//From Footer
import Footer from './200Footer/InvertedFooter';

// Pivot from a top down to a bottom up approach, will create individual components first

const mapActions = {
    fetchEvents
};

class AppRouter extends React.Component {

    componentDidMount(){
        const {  fetchEvents } = this.props;
        fetchEvents();
    }

    render() {
        return (
            <Router>
                <div>
                    <Header/>
                    <div style={{paddingTop: "40px"}}/>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/PhotoGallery" component={PhotoGallery}/>
                        <Route path="/VideoGallery" component={VideoGallery}/>
                        <Route path="/Pricing" component={Pricing}/>
                        <Route path="/Booking" component={BookingForm}/>
                        <Route path="/BookingConfirmation" component={BookingConfirmation}/>
                        <Route path="/SecretAdminEvenMoreWordsToType" component={SecretAdminEvents}/>
                    </Switch>

                    <Footer/>

                </div>
            </Router>
        )
    }
};

export default connect(null, mapActions)(AppRouter);

