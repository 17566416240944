import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import DocumentMeta from 'react-document-meta';

import 'semantic-ui-css/semantic.min.css'
import { Global } from '@emotion/core'

import { Provider } from 'react-redux'

import store from './store';
import styles from './styles';
import  './firebaseService';
import appData from './AppData';

const { meta  } = appData;

const metaTags = {
    title: 'LED Robot Party',
    description: `${meta.description}`,
    canonical: 'https://ledrobotparty.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: `${meta.keywords}`}
    }
};

ReactDOM.render(
    <DocumentMeta {...metaTags}>
        <Provider store={store}>
            <App />
            <Global styles={styles} />
        </Provider>
    </DocumentMeta>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
