// eslint-disable-next-line
import React from 'react';

//contravariant functor for component
export default class ContravariantComponent {
    constructor(FnComponent){
        this.$FnComponent = FnComponent;
    }

    // point free
    static of(FnComponent){
        return new ContravariantComponent(FnComponent);
    }

    // call the input function before passing it result to component
    // f => (b -⁠> a) -⁠> f a -⁠> f b
    contramap(fn){
        return new ContravariantComponent(props => this.$FnComponent(fn(props)));
    }


    // stack this component on top of the other component
    // a => a -⁠> a -⁠> a
    concat(other){
        return new ContravariantComponent( props =>
            <>
                {this.fold(props)}
                {other.fold(props)}
            </>
        )
    }

    // equivalent of join or flatmap
    unwrap(){
        return this.$FnComponent;
    }

    // reduce the component with props
    // f => (b -⁠> a -⁠> b) -⁠> b -⁠> f a -⁠> b
    fold(props){
        return this.$FnComponent(props);
    }

}