import { css } from '@emotion/core';
// import background from './images/background.jpg';

// background: url(${background}) no-repeat center center fixed;

export default css`
    html, body {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
         background-size: cover;
    }
`