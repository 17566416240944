import React  from 'react'
import {
    Container,
    Form,
    Header,
} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';

import AppData from '../../AppData.json';
import Names, { names_default } from './Form.0Names';
import Address, { address_default} from './Form.1Address';
import Dates, { dates_default } from './Form.2Dates';
import Services, { mapServicesToDefault } from './Form.3Services';
import SpecialInstructions from './Form.4SpecialInstructions';
import SubmitButton, { handleSubmit } from './Form.5SubmitButton';
import validationSchema from './BookingFormValidation';

const mapPropsToValues = props => ({
    ...names_default,
    ...address_default,
    ...dates_default,
    ...mapServicesToDefault(props),
});

const { booking_form, pricing } = AppData;
//putting each of the forms together
// perfect place to use contravariant
const PreForm = props =>
        <Form onSubmit={props.handleSubmit}>
            {Names
                .concat(Address)
                .concat(Dates)
                .concat(Services)
                .concat(SpecialInstructions)
                .concat(SubmitButton)
                .fold({
                    ...props,
                    ...booking_form,
                    ...pricing,
                })}
        </Form>;

const mapStateToProps = state => {
    return {
        events: state.events
    }
};

//add withRouter to get the history
const ConnectedForm = connect(
    mapStateToProps //get events from redux
)(withRouter(//add router history
    withFormik({ //get formik props
        mapPropsToValues,
        validationSchema,
        handleSubmit,
        displayName: 'BookingForm',
})(PreForm)));

const BookingForm = () => (
    <Container style={{width:  "80vw", margin: "auto", marginBottom: "20px"}}>
        <Header as='h2' icon textAlign='center' style={{
            margin: "2rem 0"
        }}>
            <Header.Content>Booking Form</Header.Content>
        </Header>
        <ConnectedForm />
    </Container>
);

export default BookingForm;