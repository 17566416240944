import React from 'react';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import { connect } from 'react-redux';
import {
    Header,
    Modal,
    Button,
} from 'semantic-ui-react'

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { fetchEvents } from "./Calendar.actions";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer



const mapState = (state) => ({
   events: state.events
});


const mapActions = {
    fetchEvents
};


class AppCalendar extends React.Component {

    state = {
        open: false,
        event: {},
    };

    componentDidMount(){
        const {  fetchEvents } = this.props;
        fetchEvents();
    }

    openModal = event => {
        this.setState({event, open:true });
    };

    closeModal = () => this.setState({open: false});

    render(){
        const { events } = this.props;
        const { event, open } = this.state;
        const { title="", start=new Date(), end=new Date() } = event;
        const startFormat = moment(start).format("dddd, MMMM Do YYYY, h:mm:ss a");
        const endFormat = moment(end).format("dddd, MMMM Do YYYY, h:mm:ss a");

        return(
            <div style={{height: "80vh", width: "80vw", margin: "auto"}}>
                <Header as='h2' icon textAlign='center'>
                    <Header.Content>Calendar</Header.Content>
                </Header>
                <BigCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={this.openModal}
                    selectable
                />
                <Modal size="mini" open={open} onClose={this.closeModal}>
                    <Modal.Header>Event: {title}</Modal.Header>
                    <Modal.Content>
                        <p>Start: {startFormat}</p>
                        <p>End: {endFormat}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='Ok' onClick={this.closeModal}/>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}


export default connect(mapState, mapActions)(AppCalendar);