import React from 'react';
import {
    Header,
    Grid,
    Segment,
    Image,
} from 'semantic-ui-react'
import { zipWith } from 'ramda';

import VideoBanner from './VideoBanner';
import firstParagraph from '../images/Home_first_para.png';
import AppData from '../AppData.json';

const { home } = AppData;
const images = [firstParagraph];

const combineObjectsWithImages = (left, right) => {
    return {
        ...left,
        imageSrc: right
    }
};

const homeWithImages = zipWith(combineObjectsWithImages, home, images);



const Content = ({title, text, imageSrc}) => (
    <Segment style={{ padding: '8em 0em', borderBottom: "none" }} vertical>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                        {title}
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                        {text}
                    </p>

                </Grid.Column>
                <Grid.Column floated='right' width={6}>
                    <Image bordered rounded size='medium' src={imageSrc} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
);


const Home = () => {
    const contentViews = homeWithImages.map((eachContent, index) => (
        <Content key={index} {...eachContent} />
    ));

    return (
        <>
            <VideoBanner />
            {contentViews}

        </>
    );
}


export default Home;