import React from 'react';
import {
    Header,
} from 'semantic-ui-react'

const VideoGallery = () => (
    <Header as='h2' icon textAlign='center'>
        <Header.Content>Video Gallery</Header.Content>
    </Header>
);

export default VideoGallery;