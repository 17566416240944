import firebase from "firebase/app";
import "firebase/firestore";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyBYwROZyk8P2lD8o1enXuKZTHgz7O2J3wc",
    authDomain: "simple-authentication-f40cc.firebaseapp.com",
    databaseURL: "https://simple-authentication-f40cc.firebaseio.com",
    projectId: "simple-authentication-f40cc",
    storageBucket: "simple-authentication-f40cc.appspot.com",
    messagingSenderId: "515003013293"
};


const firebaseService = firebase.initializeApp(config);

const db = firebaseService.firestore();
// entire app refs

export const docRef = db.doc('fantasyballoonart/app');
// const start = new Date(2019, 3, 0);
// const end = new Date(2019, 3, 1);
// console.log(start, end);

// const firebaseAppData = { events: [
//     {
//         id: 0,
//         title: 'All Day Event very long title',
//         start,
//         end,
//     }
// ]};

// console.log(firebaseService);
// docRef.set(firebaseAppData).then(function(){
//     console.log("Document written with ID: ");
// }).catch(function(error) {
//     console.error("Error adding document: ", error);
// });
// docRef.get().then(function(doc){
//     if (doc && doc.exists){
//         const data = doc.data();
//         console.log(data);
//     }
// }).catch(error => {
//     console.log("we got an error fetching the app data: ", error)
// });
// firebaseFirestore.collection("users").add({
//     first: "Ada",
//     last: "Lovelace",
//     born: 1815
// })
//     .then(function(docRef) {
//         console.log("Document written with ID: ", docRef.id);
//     })
//     .catch(function(error) {
//         console.error("Error adding document: ", error);
//     });
// db.collection("").get().then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//         console.log(`${doc.id} => ${doc.data()}`);
//     });
// });

console.log("Initializing firebase app: ", firebaseService.name);
