import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import { Container, Header, Pagination } from 'semantic-ui-react';
import  Flickr from'flickr-sdk';


const FLICKR_API_KEY = "261374dabc4ef659984b2147054fe7b5";
const flickr = new Flickr(FLICKR_API_KEY);
const Chano_photo_id = '72157706059801724';
const user_id = '161783230@N02';


const ImageWidth = 320;
const ImageHeight = 200;


class MyPhotoGallery extends Component {
    state = {
        activePage: 1,
        pagesPerView: 12,
        images: []
    };


    componentDidMount(){
        flickr.photos.search({
            photoset_id: Chano_photo_id,
            user_id: user_id
        }).then((res) =>{

            const { body } = res;
            const { photos } = body;
            const { photo } = photos;
            const images = this._buildImages(photo);

            this.setState({
                images
            });
        }).catch(function (err) {
            console.error('Please let Sang Knows', err);
        });
    }

    _buildImages(flickerPhotos=[]){
        //format to build flickr image src
        //'http://farm' + item.farm + '.staticflickr.com/' + item.server + '/' + item.id + '_' + item.secret + '.jpg'
        return flickerPhotos.map(item => {
            return {
                src: 'http://farm' + item.farm + '.staticflickr.com/' + item.server + '/' + item.id + '_' + item.secret + '.jpg',
                thumbnail: 'http://farm' + item.farm + '.staticflickr.com/' + item.server + '/' + item.id + '_' + item.secret + '.jpg',
                thumbnailWidth: ImageWidth,
                thumbnailHeight: ImageHeight,
            }
        })
    }


    render(){

        const { activePage, pagesPerView, images } = this.state;
        const start = (activePage - 1) * pagesPerView;
        const end = start + pagesPerView;
        const totalPage = images.length / pagesPerView;
        const updatedImages = images.slice(start, end);

        return(
            <Container >
                <Header size='huge' textAlign="center">Photo Gallery</Header>

                <Container style={{height: "80vh"}}>
                    <Gallery images={updatedImages} />
                </Container>
                <Container textAlign="center" fluid={true} style={{float: "left"}}>
                    <Pagination defaultActivePage={activePage}
                                totalPages={totalPage}
                                onPageChange={this.onPageChange}
                    />
                </Container>

            </Container>
        )
    }

    onPageChange = (event, {activePage}) => {
        this.setState({
            activePage
        })
    }
}


export default MyPhotoGallery;