import React from 'react';
import { Responsive } from 'semantic-ui-react'

import videoSrc from '../videos/chano_background_video1.mov';
import AppData from '../AppData.json';

const { sitename } = AppData;

const videoParentStyles = {
    position: "relative",
    width: "100%",
    height: "100vh",
    overflow: "hidden",

};

const videoStyles = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    opacity: ".80",
    objectFit: "fill",
};

const headerStyles = {
    display: "inline-block",
    textTransform: "uppercase",
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    fontSize: "4rem",
    fontShadow: "1px solid white",
    color: "yellow",
};

const headerSmallStyles = {
    display: "inline-block",
    textTransform: "uppercase",
    top: "25%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    fontSize: "1.6rem",
    color: "yellow",
};

const headerMediumStyles = {
    display: "inline-block",
    textTransform: "uppercase",
    top: "40%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    fontSize: "3rem",
    color: "yellow",
};


// background: "linear-gradient(to right, #30CFD0,  #330867 )",
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent',

const VideoBanner = () => {

    return (
        <div style={videoParentStyles}>
            <video autoPlay muted loop style={videoStyles}>
                <source src={videoSrc} type="video/mp4" />
                Your browser is not supported - video of robot led.
            </video>

            <Responsive minWidth={320} maxWidth={767}>
                <h1 style={headerSmallStyles}>
                    {sitename}
                </h1>
            </Responsive>
            <Responsive minWidth={768} maxWidth={991}>
                <h1 style={headerMediumStyles}>
                    {sitename}
                </h1>
            </Responsive>
            <Responsive minWidth={992} >
                <h1 style={headerStyles}>
                    {sitename}
                </h1>
            </Responsive>

        </div>
    )
};

export default VideoBanner;