import React from 'react';
import { Menu, Container, Icon } from 'semantic-ui-react';
import ActualMenuItems from './ActualMenuItems';

import Appmenu from '../AppData.json';

const { sitename } = Appmenu;

class MobileMenu extends React.Component {

    state = {
        isMenuVisible: false,
    };

    toggleMenu = () => {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    };


    render(){
        return (<Menu fixed="top" inverted vertical fluid>
                <Container>
                    <Menu.Item onClick={this.toggleMenu}>
                        {sitename}<Icon name="sidebar" />
                    </Menu.Item>
                    {(this.state.isMenuVisible)? <ActualMenuItems onClick={this.toggleMenu}/>: null}
                </Container>
            </Menu>
        );
    }
}


export default MobileMenu;