import React from 'react';
import {
    Form,
    Message,
} from 'semantic-ui-react'
import * as Yup from 'yup';

import ContraComponent from '../../utils/ContravariantComponent';

export const names_default = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
};

export const names_validation = {
    first_name: Yup.string()
        .min(1, 'Min is 1 character!')
        .max(20, 'Max is 20 characters!')
        .required('Required'),
    last_name: Yup.string()
        .min(1, 'Min is 1 character!')
        .max(20, 'Max is 20 characters!')
        .required('Required'),
    phone: Yup.string()
        .length(10, 'Phone numbers must be exactly 10 numbers.')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
};

// stop using props, but use object destructing
const Names = ({   handleChange, handleBlur,
                   names_title, names_message, first_name_label,
                   last_name_label, phone_label, phone_placeholder, email_label,
                   errors, touched, values }) =>
        <>
            <Message>
                <Message.Header>{names_title}</Message.Header>
                <p>{names_message}</p>
            </Message>
            <Form.Group widths="equal">
                <Form.Input
                    fluid label={first_name_label}
                    placeholder={first_name_label}
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.first_name && touched.first_name}
                />
                <Form.Input
                    fluid label={last_name_label}
                    placeholder={last_name_label}
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.last_name && touched.last_name}
                />
                <Form.Input
                    fluid label={phone_label}
                    placeholder={phone_placeholder}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone}
                />
                <Form.Input
                    fluid label={email_label}
                    placeholder={email_label}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                />

            </Form.Group>
        </>;

export default ContraComponent.of(Names).contramap(props => ({...props, ...props.form_names}));
