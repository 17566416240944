import React from 'react';
import { Form } from 'semantic-ui-react'

import ContravariantComponent from "../../utils/ContravariantComponent";
import {docRef} from "../../firebaseService";
import {addEvents} from "../Calendar/Calendar.actions";
import emailjs from "../../EmailjsService";

const serviceID = 'booking_service';
const templateID = 'booking_confirmation';

export const handleSubmit = ({  last_name, first_name, phone, email,
                                street, city, state, zip,
                                day, time,
                                selected_value, item_description, total,
                             },
                             {props }) => {

    const {events, dispatch} = props;

    const dayArray = day.split("-").map(each => parseInt(each));
    const year = dayArray[2];
    const month = dayArray[1];
    const today = dayArray[0];
    console.log(year, month, today);
    const timeArray = time.split(":").map(each => parseInt(each));
    const startHour = timeArray[0];
    const endHour = startHour + 1;
    //get the next id from the list of old events
    const maxId = events.reduce((acc, currentEvent) => {
        const {id} = currentEvent;
        return (acc > id) ? acc : id;
    }, 0) + 1;

    //construct the events of Date, id and title
    // could also improve the title with activities
    // Date from JS starts at 0, and the other calendar starts at 1
    const newEvent = {
        id: maxId,
        title: `${last_name}  event-${maxId}`,
        start: new Date(year, month - 1, today, startHour),
        end: new Date(year, month -1 , today, endHour)
    };
    const updatedEvents = events.concat(newEvent);
    const eventsObj = {events: updatedEvents};

    //post data to firebase
    docRef.set(eventsObj)
        .then(() => {
            //dispatch the new events
            dispatch(addEvents(eventsObj));
            //send email confirmation
            emailjs.send(serviceID, templateID, {
                last_name, first_name, phone, email,
                street, city, state, zip,
                day, time,
                selected_value, item_description, total,
                eventId: `event-${maxId}`,
            });
            // change to the booking confirmation page
            props.history.push('/BookingConfirmation', {  last_name, first_name, phone, email,
                street, city, state, zip,
                day, time,
                selected_value, item_description, total,
                eventId: `event-${maxId}`,
            });
        })
        .catch(error => {
            console.error(error);
        })
};

export default ContravariantComponent.of(() =>
    <Form.Button positive floated='right' type="submit">Book Event</Form.Button>);