import React from 'react';
import {
    Container,
    Grid,
    Header,
    Icon,
    Segment,
    Image,
    Label,
} from 'semantic-ui-react'

import instagram from '../images/instagram.png';
import yelp from '../images/yelp.jpg';
import pinterest from '../images/pininterest.png';
import fantasyBalloonArtLogo from '../images/fantasy_balloon_art_logo.jpg';
import appData from '../AppData';

const { footer } = appData;
const { email_value, fantasy_balloon_art } = footer;

const InvertedFooter = () => (
    <Segment inverted style={{ margin: '5em 0em 0em', padding: '5em 0em' }} vertical>
        <Container textAlign='center'>
            <Header  inverted>
                Social Media
            </Header>
            <Grid columns={3} stackable divided>
                <Grid.Row>
                    <Grid.Column>
                        <Image size='medium'  as='a' alt='Instagram link'
                               src={instagram} href="https://www.instagram.com/fantasy_balloon_art/"
                               target='_blank'/>
                    </Grid.Column>
                    <Grid.Column>
                        <Image size='medium'  as='a' alt='Yelp link'
                               src={yelp} href="https://www.yelp.com/biz/led-robot-party-huntington-beach-2?osq=led+robot+party"
                               target='_blank'/>
                    </Grid.Column>
                    <Grid.Column>
                        <Image size='medium'  as='a' alt='Pinterest link'
                               src={pinterest} href="https://www.pinterest.com/crecencianom/pins/"
                               target='_blank'/>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
            <Header  inverted>
                Contact Us
            </Header>
            <Grid columns={3} stackable divided>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>

                    <Grid.Column>
                        <Label as="a" href={`mailto:${email_value}`}  size="huge" style={{    marginBottom: "1rem"}}>
                            <Icon name="mail" />{email_value}
                        </Label>
                        <Label size="huge" style={{    marginBottom: "1rem"}}>
                            <Icon name="phone" />(714) 541-5663
                        </Label>
                        <Label size="medium" style={{    marginBottom: "1rem"}}>
                            For other services:
                            <Image size='medium'  as='a' alt='Fantasy Balloon Art link'
                                   src={fantasyBalloonArtLogo} href={fantasy_balloon_art}

                                   target='_blank'/>
                        </Label>

                    </Grid.Column>

                    <Grid.Column>


                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </Container>
    </Segment>
);

export default InvertedFooter;