import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import appText from '../AppData.json'

const { menu } = appText;
const { home, photo_gallery, pricing, booking_form } = menu;



const ActualMenuItems = ({onClick}) => (
    <>
        <Menu.Item as={Link} to="/" onClick={onClick} >
            {home}
        </Menu.Item>
        <Menu.Item as={Link} to="/PhotoGallery" onClick={onClick}>
        {photo_gallery}
        </Menu.Item>
        <Menu.Item as={Link} to="/Pricing" onClick={onClick}>
                {pricing}
        </Menu.Item>
        <Menu.Item as={Link} to="/Booking" onClick={onClick}>
            {booking_form}
        </Menu.Item>
    </>
);

export default ActualMenuItems;