import * as Yup from 'yup';

import { names_validation } from "./Form.0Names";
import { address_validation} from "./Form.1Address";
import { dates_validation } from "./Form.2Dates";

const validatonSchema = Yup.object().shape({
    ...names_validation,
    ...address_validation,
    ...dates_validation,
});

export default  validatonSchema;