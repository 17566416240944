import React from 'react';
import {
    Header,
    Container,
    Card,
    Divider,
    Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';

import { deleteEvent } from "./Calendar/Calendar.actions";


const mapState = (state) => ({
    events: state.events
});

const mapActions = {
    deleteEvent,
};


const SecretAdminEvents = props => {
    const { events, deleteEvent } = props;
    const cardEvents = events.map( (eachEvent, index) => {
        const { title, start, end } = eachEvent;
       return (
           <Card key={index}>
               <Card.Content>
                   <Card.Header>{title}</Card.Header>
                   <Card.Description>
                       <p>Start: {moment(start).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                       <p>End: {moment(end).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                   </Card.Description>
               </Card.Content>
               <Card.Content extra>
                       <Button basic color='red' floated="right" onClick={() => deleteEvent(eachEvent)}>
                           Delete
                       </Button>
               </Card.Content>
           </Card>
       )
    });
    return (
        <Container>
            <Header as='h2' icon textAlign='center'>
                <Header.Content>Secret Admin Events</Header.Content>
            </Header>
            <Divider horizontal>List of Events</Divider>
            <Card.Group stackable>
                {cardEvents}
            </Card.Group>

        </Container>
    );
};

export default connect(mapState, mapActions)(SecretAdminEvents);