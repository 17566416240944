import React from 'react'
import {
    Form,
    Message,
} from 'semantic-ui-react'
import * as Yup from "yup";

import ContraComponent from '../../utils/ContravariantComponent';

export const address_default = {
    street: '',
    city: '',
    state: '',
    zip: '',
};

export const address_validation = {
    street: Yup.string().required("Street is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    zip: Yup.string()
        .length(5, "Zip must be exactly 5 numbers")
        .required("Street is required."),
};

const Address = ({   handleChange, handleBlur,
                     address_title, address_message, street_label, city_label, state_label, zip_label,
                     values, errors, touched }) =>
        <>
            <Message>
                <Message.Header>{address_title}</Message.Header>
                <p>{address_message}</p>
            </Message>
            <Form.Group widths='equal'>
                <Form.Input
                    fluid label={street_label}
                    placeholder={street_label}
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.street && touched.street}
                />
            </Form.Group>
            <Form.Group >
                <Form.Input
                    fluid label={city_label}
                    placeholder={city_label}
                    width={10}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.city && touched.city}
                />
                <Form.Input
                    fluid label={state_label}
                    placeholder={state_label}
                    width={2}
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.state && touched.state}
                />
                <Form.Input
                    fluid label={zip_label}
                    placeholder={zip_label}
                    width={4}
                    name="zip"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.zip && touched.zip}
                />
            </Form.Group>
        </>;

export default ContraComponent.of(Address).contramap(props => ({...props, ...props.form_address}));