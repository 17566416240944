import React from 'react';
import {
    Header,
    Container,
    Segment,
    Table,
} from 'semantic-ui-react'

const BookingConfirmation = props => {

    const { location } = props;
    const { state } = location;


    return (
        <Container>
            <Header as='h2' icon textAlign='center'>
                <Header.Content>
                    Booking Confirmation
                </Header.Content>
            </Header>
            <Segment raised>
                <Header>An email has been sent to you, someone will call you to talk about payments shortly.</Header>
                <Table celled inverted selectable>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name:</Table.Cell>
                            <Table.Cell>{state.first_name} {state.last_name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact:</Table.Cell>
                            <Table.Cell>phone: {state.phone}, email: {state.email}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Address:</Table.Cell>
                            <Table.Cell>
                                {state.street},<br />
                                {state.city} {state.zip}, {state.state}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date of Services:</Table.Cell>
                            <Table.Cell>
                                day: {state.day} time:{state.time}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Type of Service and Pricing:</Table.Cell>
                            <Table.Cell>
                                {state.selected_value}: {state.item_description}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Total:</Table.Cell>
                            <Table.Cell>
                                ${state.total}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>

        </Container>
    );
}

export default BookingConfirmation;